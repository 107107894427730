import { firebase, firestore } from '@/components/utils/firebase'
import { initializeDatabase } from '@shared/database'
import { createApp } from 'vue'
import VueClipboard from 'vue-clipboard2'
import router from './router'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'

const app = createApp(App)

// chatplus script
// var d = document
// var s = 'https://app.chatplus.jp/cp.js'
// d['__cp_d'] = 'https://app.chatplus.jp'
// d['__cp_c'] = '64310543_1'
// var a = d.createElement('script')
// var m = d.getElementsByTagName('script')[0]
// a.async = true
// a.src = s
// m.parentNode.insertBefore(a, m)

initializeDatabase(firestore, {
  FieldValue: firebase.firestore.FieldValue,
  FieldPath: firebase.firestore.FieldPath
})

// https://t-salad.com/vue-forbidden-scroll/
// イベント設定用関数
function noScroll(event) {
  event.preventDefault()
}
// スクロール禁止
app.config.globalProperties.$noScroll = function () {
  // SP
  document.addEventListener('touchmove', noScroll, { passive: false })
  // PC
  document.addEventListener('mousewheel', noScroll, { passive: false })
}
// スクロール禁止を解除
app.config.globalProperties.$returnScroll = function () {
  // SP
  document.removeEventListener('touchmove', noScroll, { passive: false })
  // PC
  document.removeEventListener('mousewheel', noScroll, { passive: false })
}

// Cancel可能なタイマー
app.config.globalProperties.$asyncSetTimeout = function (msec, func) {
  let timeoutId
  let r
  const exec = () =>
    new Promise((res) => {
      r = res
      timeoutId = setTimeout(async () => {
        timeoutId = null
        await func()
        res()
      }, msec)
    })
  return {
    exec,
    cancel: () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
        timeoutId = null
        r()
      }
    }
  }
}

app.use(VueClipboard)
app.use(vuetify)

app.use(router)
app.use(store)

app.mount('#app')
