import { config, api, siteKey } from './config'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/auth'
import 'firebase/compat/functions'
import 'firebase/compat/app-check'

const isLocal =
  window.location.hostname === 'localhost' ||
  window.location.hostname === '127.0.0.1'

const firebaseApp = firebase.initializeApp(config)

const storage = firebaseApp.storage()
const auth = firebaseApp.auth()
const firestore = firebaseApp.firestore()
const functions = firebaseApp.functions('asia-northeast1')

if (!isLocal) {
  const appCheck = firebase.appCheck()
  appCheck.activate(siteKey, true)
  appCheck
    .getToken()
    .then(() => {
      console.log('App Check vefified')
    })
    .catch((error) => {
      console.error(error)
    })
}

export default firebaseApp.firestore()
export { firestore, firebase, storage, auth, api, functions }
